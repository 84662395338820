import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type TransactionData = {
  transactionId: string;
  orderId: string;
  userId: string;
  refferalCode: string;
  imageUrl: string;
  amount: string;
};

const ConfirmPage: React.FC = () => {
  const location = useLocation();
  const [transactionData, setTransactionData] = useState<TransactionData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch transaction data on page load
  const fetchTransactionData = () => {
    setLoading(true);
    setError(null);

    const params = new URLSearchParams(location.search);
    const transactionId = params.get('transactionId') || '';
    const orderId = params.get('orderId') || '';

    if (transactionId) {
      fetch(`https://artelu-api.vercel.app/api/get-transaction-data?transactionId=${orderId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch transaction data');
          }
          return response.json();
        })
        .then(data => {
          setTransactionData(data);
          setLoading(false);
        })
        .catch(error => {
          console.error("Error fetching transaction data:", error);
          setError('ไม่พบข้อมูลการทำธุรกรรม');
          setLoading(false);
        });
    } else {
      setError('ไม่พบ Transaction ID');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionData();
  }, []);

  // Call the payment callback when transactionData is set
  useEffect(() => {
    if (transactionData) {
      const { userId, refferalCode, imageUrl, orderId } = transactionData;
      const callbackUrl = `https://artelu-api.vercel.app/api/paymentedUserBye/callback?userId=${encodeURIComponent(
        userId
      )}&refferalCode=${refferalCode}&imageUrl=${encodeURIComponent(
        imageUrl
      )}&orderId=${orderId}`;

      fetch(callbackUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to call payment callback');
          }
          console.log("Payment callback successful");
        })
        .catch(error => console.error("Error calling payment callback:", error));
    }
  }, [transactionData]);

  if (loading) {
    return <p>กำลังโหลดข้อมูล...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="app d-flex align-items-center justify-content-center vh-100">
      <div className="containers p-5 text-center">
        <h2 className="mb-4">การชำระเงินสำเร็จ</h2>
        <p className="text-muted mb-4">สินค้าได้ส่งไปที่ LINE ของท่านเสร็จสิ้น</p>
        <p className="text-muted mb-4">ขอบคุณที่เลือกสินค้า Artteluh Thailand</p>
        {transactionData && (
          <>
            <p>Transaction ID: {transactionData.transactionId}</p>
            <p>จำนวนเงิน: {transactionData.amount} บาท</p>
          </>
        )}
        <button onClick={() => window.location.href = '/'} className="btn btn-primary mt-3">กลับไปหน้าแรก</button>
      </div>
    </div>
  );
};

export default ConfirmPage;
