import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import liff from '@line/liff'; // Import LIFF SDK
import "./App.css"; // ใส่ฟอนต์ 'Kanit'

const DistributorSignup: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null); // เก็บ userId จาก LIFF
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    province: "",
    district: "",
    postalCode: "",
    bankName: "",
    bankAccount: "",
    image: null as File | null, // ปรับให้เก็บไฟล์หรือค่าว่าง (null)
  });

  const [previewImage, setPreviewImage] = useState<string | null>(null);

  // Initialize LIFF และดึง userId
  useEffect(() => {
    liff.init({ liffId: '2006373201-zYZl0bpa' }) // ใส่ LIFF ID ของคุณ
      .then(() => {
        if (liff.isLoggedIn()) {
          liff.getProfile().then(profile => {
            setUserId(profile.userId);  // เก็บ userId ลงใน state
            
          }).catch(err => {
            console.error('Error getting profile: ', err);
          });
        } else {
          liff.login();  // ถ้ายังไม่ได้ล็อกอิน ให้พาไปที่หน้าล็อกอิน
        }
      })
      .catch(err => {
        console.error('LIFF initialization failed: ', err);
      });
  }, []);

  const updateFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      // ตรวจสอบขนาดไฟล์และประเภทไฟล์
      if (file.size > 3 * 1024 * 1024) {
        // 3MB
        Swal.fire({
          icon: "error",
          title: "ไฟล์ใหญ่เกินไป",
          text: "กรุณาอัปโหลดไฟล์ขนาดไม่เกิน 3MB",
        });
        return;
      }

      if (!["image/jpeg", "image/png"].includes(file.type)) {
        Swal.fire({
          icon: "error",
          title: "ประเภทไฟล์ไม่ถูกต้อง",
          text: "กรุณาอัปโหลดไฟล์ประเภท .png หรือ .jpg",
        });
        return;
      }

      // เก็บไฟล์ใน state
      setFormData({
        ...formData,
        image: file, // เก็บไฟล์ใน state
      });

      // แสดง preview รูปภาพ
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateFields = (requiredFields: string[]) => {
    const missingFields = requiredFields.filter(
      (field) => !formData[field as keyof typeof formData]
    );

    if (missingFields.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "กรอกข้อมูลไม่ครบ",
        text: "กรุณากรอกข้อมูลให้ครบทุกช่อง",
      });
      return false;
    }
    return true;
  };

  const nextStep = () => {
    if (
      step === 1 &&
      !validateFields([
        "firstName",
        "lastName",
        "phone",
        "address",
        "province",
        "district",
        "postalCode",
      ])
    )
      return;
    if (step === 2 && !validateFields(["bankName", "bankAccount"])) return;
    if (step === 3 && !validateFields(["image"])) return;

    setStep(step + 1);
  };

  const prevStep = () => setStep(step - 1);

  const handleOTPVerification = async () => {
    if (userId) {
      try {
        const response = await fetch('https://artelu-api.vercel.app/api/send-line-message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            message: 'การยืนยัน OTP สำเร็จ! ขอบคุณที่สมัครสมาชิกกับเรา',
          }),
        });
  
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'ยืนยัน OTP สำเร็จ',
            text: 'ข้อความถูกส่งไปที่แชท Line ของคุณแล้ว',
          });
        } else {
          const errorData = await response.json();
          console.error('Error:', errorData);
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: `ไม่สามารถส่งข้อความได้: ${errorData.message}`,
          });
        }
      } catch (error) {
        console.error('Error sending message:', error);
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถส่งข้อความไปยัง Line ของคุณได้',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ไม่พบข้อมูล',
        text: 'ไม่สามารถส่งข้อความได้ เนื่องจากไม่มีข้อมูลผู้ใช้',
      });
    }
  };
  
  
  
  

  const handleSubmit = async () => {
    // แสดงตัวโหลดเมื่อเริ่มส่งข้อมูล
    Swal.fire({
      title: "กำลังส่งข้อมูล...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const formDataToSend = new FormData();
      if (userId) {
        formDataToSend.append("userId", userId); // เพิ่ม userId ลงใน FormData
      }
      formDataToSend.append("name", formData.firstName);
      formDataToSend.append("lastname", formData.lastName);
      formDataToSend.append("phoneNumber", formData.phone);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("province", formData.province);
      formDataToSend.append("district", formData.district);
      formDataToSend.append("postalCode", formData.postalCode);
      formDataToSend.append("bankName", formData.bankName);
      formDataToSend.append("bankAccount", formData.bankAccount);
      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }

      const response = await fetch(
        "https://artelu-api.vercel.app/api/register/referral-code",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        const data = await response.json();
        await Swal.fire({
          icon: "success",
          title: "ลงทะเบียนสำเร็จ",
          text: `รหัสตัวแทนของคุณคือ ${data.referralCode}`,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง",
        });
      }
    } catch (error) {
      await Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถติดต่อเซิร์ฟเวอร์ได้",
      });
    } finally {
      // ปิดตัวโหลดหลังจากที่ popup แสดงเรียบร้อยแล้ว
      Swal.close();
      window.location.href = "/";
    }
  };

  

  return (
    <div className="signup-container container">
      {/* เส้นลางรถไฟ (Progress Steps) */}
      <div className="progress-steps d-flex justify-content-between mb-4">
        <div className={`step ${step >= 1 ? "active" : ""}`}>ข้อมูล</div>
        <div className={`step ${step >= 2 ? "active" : ""}`}>ธนาคาร</div>
        <div className={`step ${step >= 3 ? "active" : ""}`}>เอกสาร</div>
        <div className={`step ${step >= 4 ? "active" : ""}`}>สถานะ</div>
      </div>

      {/* ขั้นตอนที่ 1: กรอกข้อมูลส่วนตัว */}
      {step === 1 && (
        <div className="row">
          <h5 className="mb-3 text-center">1. กรอกข้อมูลส่วนตัว</h5>
          <div className="row-md-6 mb-3 d-flex justify-content-between">
            <div className="col">
              <label>Name (ชื่อจริง):</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={updateFormData}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Lastname (นามสกุล):</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={updateFormData}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-12 mb-3">
            <label>Phone Number (เบอร์โทรศัพท์):</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={updateFormData}
              className="form-control"
            />
          </div>
          <div className="col-md-12">
            <label>Address (ที่อยู่):</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={updateFormData}
              className="form-control"
            />
          </div>
          <div className="row-md-6 d-flex justify-content-between">
            <div className="col">
              <label>Province (จังหวัด):</label>
              <input
                type="text"
                name="province"
                value={formData.province}
                onChange={updateFormData}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>District (อำเภอ):</label>
              <input
                type="text"
                name="district"
                value={formData.district}
                onChange={updateFormData}
                className="form-control"
              />
            </div>
          </div>
          <div className="col mb-3">
            <label>Postal Code (ไปรษณีย์):</label>
            <input
              type="text"
              name="postalCode"
              value={formData.postalCode}
              onChange={updateFormData}
              className="form-control"
            />
          </div>
          <div className="col-md-12 d-flex justify-content-between">
            <button
              disabled={step === 1}
              onClick={prevStep}
              className="btn btn-secondary"
            >
              Back
            </button>
            <button onClick={nextStep} className="btn btn-primary">
              Next
            </button>
          </div>

          <div className="col-md-12 mt-5 text-secondary">
          <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
          <p className="font-weight-bold">โปรดอ่านก่อนสมัคร</p>
          
          <p>
            <strong>นโยบายการเก็บข้อมูล:</strong>
            <br />
            ข้อมูลจะถูกใช้เพื่อยืนยันตัวตนในการเป็นตัวแทนขายเท่านั้น
            เพื่อยืนยันการทำรายได้และโอนเงินให้กับตัวแทนจำหน่าย
            เราจะไม่ใช้ข้อมูลผู้ใช้ในกรณีอื่นใด
            หรือการเปิดเผยข้อมูลส่วนบุคคลในรูปภาพ
          </p>

          <p>
            <strong>การทำรายได้:</strong>
            <br />
            รอบโอนเงินจะมี 2 วัน คือ วันที่ 1 และวันที่ 15 ของแต่ละเดือน
            <br />
            ผู้ขายจะได้รับยอดรายได้ตามจำนวนที่ขายได้
            รายได้จะถูกโอนตามรอบที่กำหนด
          </p>
        </div>

        </div>

        
      )}

      {/* ขั้นตอนที่ 2: กรอกข้อมูลธนาคาร */}
      {step === 2 && (
        <div className="row">
          <h5 className="mb-3 text-center">2. กรอกข้อมูลธนาคาร</h5>
          <div className="col-md-6 mb-3">
            <label>Bank (ธนาคาร):</label>
            <select
              name="bankName"
              value={formData.bankName}
              onChange={updateFormData}
              className="form-control"
            >
              <option value="">Select Bank</option>
              <option value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</option>
              <option value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</option>
              <option value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</option>
              <option value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</option>
              <option value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</option>
              <option value="ธนาคารทหารไทย">ธนาคารทหารไทย</option>
              <option value="ธนาคารออมสิน">ธนาคารออมสิน</option>
              <option value="ธนาคารยูโอบี">ธนาคารยูโอบี</option>
              <option value="ธนาคารธนชาติ">ธนาคารธนชาติ</option>
              <option value="ธนาคาร CIMB Thai">ธนาคาร CIMB Thai</option>
              <option value="ธนาคาร Citibank Thailand">
                ธนาคาร Citibank Thailand
              </option>
              <option value="ธนาคารเกียรตินาคิน">ธนาคารเกียรตินาคิน</option>
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label>Bank Account Number (เลขบัญชี):</label>
            <input
              type="text"
              name="bankAccount"
              value={formData.bankAccount}
              onChange={updateFormData}
              className="form-control"
            />
          </div>
          <p className="text-secondary">
            <strong>กรอกข้อมูลธนาคาร:</strong>
            <br />
            โปรดกรอกชื่อธนาคารให้ตรงกับเลขบัญชีของท่าน เมื่อรอบโอนเงินจะเข้าบัญชีโดยตรง **สำคัญ
          </p>
          <div className="col-md-12 d-flex justify-content-between">
            <button onClick={prevStep} className="btn btn-secondary">
              Back
            </button>
            <button onClick={nextStep} className="btn btn-primary">
              Next
            </button>
          </div>

          <div className="col-md-12 mt-5 text-secondary">
          <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
          <p className="font-weight-bold">โปรดอ่านก่อนสมัคร</p>
          
          <p>
            <strong>นโยบายการเก็บข้อมูล:</strong>
            <br />
            ข้อมูลจะถูกใช้เพื่อยืนยันตัวตนในการเป็นตัวแทนขายเท่านั้น
            เพื่อยืนยันการทำรายได้และโอนเงินให้กับตัวแทนจำหน่าย
            เราจะไม่ใช้ข้อมูลผู้ใช้ในกรณีอื่นใด
            หรือการเปิดเผยข้อมูลส่วนบุคคลในรูปภาพ
          </p>

          <p>
            <strong>การทำรายได้:</strong>
            <br />
            รอบโอนเงินจะมี 2 วัน คือ วันที่ 1 และวันที่ 15 ของแต่ละเดือน
            <br />
            ผู้ขายจะได้รับยอดรายได้ตามจำนวนที่ขายได้
            รายได้จะถูกโอนตามรอบที่กำหนด
          </p>
        </div>
          
        </div>
      )}

      {/* ขั้นตอนที่ 3: อัพโหลดเอกสาร */}
      {step === 3 && (
        <div className="row">
          <h5 className="mb-3 text-center">3. อัปโหลดเอกสารบัตรประชาชน</h5>
          <div className="col-md-12 mb-3">
            <label>Upload ID Card (เอกสารบัตรประชาชน):</label>
            <input
              type="file"
              name="image"
              onChange={handleFileUpload}
              className="form-control"
            />
          </div>
          {previewImage && (
            <div className="col-md-12 mb-3">
              <img
                src={previewImage}
                alt="ID Card Preview"
                className="img-thumbnail"
                style={{ maxWidth: "300px" }}
              />
            </div>
          )}
                    <p className="text-secondary">
            <strong>เลือกไฟล์ภาพ เอกสารบัตรประชาชน:</strong>
            <br />
            ไฟล์ภาพที่รองรับ .png .jpg  | ขนาดไม่เกิน 3MB
          </p>
          <div className="col-md-12 d-flex justify-content-between">
            <button onClick={prevStep} className="btn btn-secondary">
              Back
            </button>
            <button onClick={nextStep} className="btn btn-primary">
              Next
            </button>
          </div>

          <div className="col-md-12 mt-5 text-secondary">
          <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
          <p className="font-weight-bold">โปรดอ่านก่อนสมัคร</p>
          
          <p>
            <strong>นโยบายการเก็บข้อมูล:</strong>
            <br />
            ข้อมูลจะถูกใช้เพื่อยืนยันตัวตนในการเป็นตัวแทนขายเท่านั้น
            เพื่อยืนยันการทำรายได้และโอนเงินให้กับตัวแทนจำหน่าย
            เราจะไม่ใช้ข้อมูลผู้ใช้ในกรณีอื่นใด
            หรือการเปิดเผยข้อมูลส่วนบุคคลในรูปภาพ
          </p>

          <p>
            <strong>การทำรายได้:</strong>
            <br />
            รอบโอนเงินจะมี 2 วัน คือ วันที่ 1 และวันที่ 15 ของแต่ละเดือน
            <br />
            ผู้ขายจะได้รับยอดรายได้ตามจำนวนที่ขายได้
            รายได้จะถูกโอนตามรอบที่กำหนด
          </p>
        </div>
        </div>
      )}
      {/* ขั้นตอนที่ 4: รอตรวจสอบ */}
      {step === 4 && (
        <div className="row">
        <h5 className="mb-3 text-center">
          4. ยืนยันการส่งข้อมูลสมัครเป็นตัวแทนจำหน่าย
        </h5>
        <div className="col-md-12 mt-5 text-secondary">
        <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
          <p className="font-weight-bold">อ่านก่อนกดส่ง</p>
          
          <p>
            <strong>โปรดรอการอนุมัติ:</strong>
            <br />
            การอนุมัติจะใช้เวลาตรวจสอบ 1-2 วันทำการ (ไม่นับวันหยุดเสาร์อาทิตย์)
            <br />
            เมื่อท่านได้รับ SMS ยืนยันการอนุมัติแล้ว
            ท่านจะสามารถใช้เลขตัวแทนจำหน่ายได้
          </p>
          <p>
            <strong>นโยบายการเก็บข้อมูล:</strong>
            <br />
            ข้อมูลจะถูกใช้เพื่อยืนยันตัวตนในการเป็นตัวแทนขายเท่านั้น
            เพื่อยืนยันการทำรายได้และโอนเงินให้กับตัวแทนจำหน่าย
            เราจะไม่ใช้ข้อมูลผู้ใช้ในกรณีอื่นใด
            หรือการเปิดเผยข้อมูลส่วนบุคคลในรูปภาพ
          </p>

          <p>
            <strong>การทำรายได้:</strong>
            <br />
            รอบโอนเงินจะมี 2 วัน คือ วันที่ 1 และวันที่ 15 ของแต่ละเดือน
            <br />
            ผู้ขายจะได้รับยอดรายได้ตามจำนวนที่ขายได้
            รายได้จะถูกโอนตามรอบที่กำหนด
          </p>
        </div>

        <div className="col-md-12 text-center">
          <button onClick={handleSubmit} className="btn btn-success">
            ยืนยัน
          </button>
        </div>
      </div>
      )}
    </div>
  );
};

export default DistributorSignup;
