import React, { useEffect, useState } from "react";
import "./App.css";
import liff from "@line/liff";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";

const HomePage: React.FC<{
  nextStep: () => void;
  formData: any;
  updateFormData: (data: any) => void;
}> = ({ nextStep, formData, updateFormData }) => {
  const [userId, setUserId] = useState<string | null>(null); // เก็บ userId จาก LIFF
  const [imageLoading, setImageLoading] = useState({
    titleText: true,
    mainImage: true,
    buyButton: true,
  }); // สถานะการโหลดแต่ละภาพ

  useEffect(() => {
    // Initialize LIFF
    liff.init({ liffId: '2006373201-OGnp2WAN' }) // ใส่ LIFF ID ของคุณ
      .then(() => {
        if (liff.isLoggedIn()) {
          liff.getProfile().then(profile => {
            setUserId(profile.userId); // เก็บ userId ลงใน state
            updateFormData({ userId: profile.userId }); // ส่ง userId เข้า formData
          }).catch(err => {
            console.error('Error getting profile: ', err);
            Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถรับข้อมูลผู้ใช้ได้', 'error');
          });
        } else {
          liff.login();  // ถ้ายังไม่ได้ล็อกอิน ให้พาไปที่หน้าล็อกอิน
        }
      })
      .catch(err => {
        console.error('LIFF initialization failed: ', err);
        Swal.fire('เกิดข้อผิดพลาด', 'การเริ่มต้น LIFF ล้มเหลว', 'error');
      });
  }, []);

  const handleImageLoaded = (imageKey: keyof typeof imageLoading) => {
    setImageLoading((prev) => ({ ...prev, [imageKey]: false })); // เมื่อโหลดเสร็จจะเปลี่ยนเป็น false
  };

  return (
    <div className="app">
      <div className="containers">
        {/* ส่วน header */}
        <div className="d-flex justify-content-center">
          {imageLoading.titleText && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <img
            src="/images/home/title text.png"
            alt="LAKSMI"
            className="header-image"
            onLoad={() => handleImageLoaded("titleText")}
            style={{ display: imageLoading.titleText ? "none" : "block" }} // ซ่อนภาพขณะโหลด
          />
        </div>

        {/* ส่วน body */}
        <div className="body text-center">
          {imageLoading.mainImage && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <img
            src="/images/home/title bg.png"
            alt="Main"
            className="main-image"
            onLoad={() => handleImageLoaded("mainImage")}
            style={{ display: imageLoading.mainImage ? "none" : "block" }}
          />
        </div>

        {/* ปุ่มกด */}
        <div className="d-flex justify-content-center">
          {imageLoading.buyButton && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50px" }}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <img
            src="/images/bt_buy1.png"
            alt="กลับ"
            style={{
              cursor: "pointer",
              width: "125px",
              height: "auto",
              display: imageLoading.buyButton ? "none" : "block",
            }}
            onClick={nextStep}
            onLoad={() => handleImageLoaded("buyButton")}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
